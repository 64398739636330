import React from "react"
import DynamicComponent from "../components/DynamicComponent"
import Layout from "../components/Layout"
import useStoryblok from "../lib/storyblok"

export default function Album({ pageContext, location }) {
  const story = useStoryblok(pageContext.story, location)
  return (
    <Layout
      location={location}
      pageDescription={story.content.intro}
      pageImage={story.content.image}
      pageTitle={story.content.title}
    >
      <DynamicComponent
        blok={story.content}
        createdAt={story.created_at}
        key={story._uid}
      />
    </Layout>
  )
}
